<script setup lang="ts">
interface IProps {
  isVisible: boolean,
  closeBtn?: boolean,
  additionalClasses?: string
}

const props = withDefaults(defineProps<IProps>(), {
  isVisible: false,
  closeBtn: true,
});

defineEmits(['close']);

watch(() => props.isVisible, (newVal) => {
  if (newVal) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
});
</script>

<template>
  <div v-if="isVisible" class="flex fixed z-10 top-0 left-0 w-full h-full items-center bg-zinc-950 bg-opacity-90 py-5 overflow-y-auto">
    <div class="p-12 shadow-md max-md:p-6 bg-white dark:bg-zinc-800 dark:border dark:border-zinc-700 rounded-xl relative w-[90%] max-w-[400px] m-auto" :class="additionalClasses">
      <div v-if="closeBtn" class="text-right mb-6">
        <button @click="$emit('close')">
          <Icon name="fm:cross" class="text-lg font-light" />
        </button>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped>

</style>