<script setup lang="ts">
interface IProps {
  name: string;
  image?: string | null;
  size?: number;
}

const props = withDefaults(defineProps<IProps>(), {
  image: null,
  size: 40
});

const dynamicStyles = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}));

const firstLetter = computed(() => props.name.substring(0, 1));
</script>

<template>
  <div class="w-max h-max rounded-full bg-[#EEE1FE] dark:bg-[#3F1978] !text-zinc-900 dark:!text-[#EEE1FE] overflow-hidden">
    <nuxt-img
        v-if="image"
        :src="image"
        :width="size"
        :height="size"
        quality="80"
    />

    <div v-else :style="dynamicStyles" class="text-xl uppercase flex items-center text-center justify-center font-semibold leading-none">
      {{ firstLetter }}
    </div>
  </div>
</template>

<style scoped>

</style>